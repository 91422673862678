@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {
    .input-style {
        @apply mt-1 p-2 w-full border rounded-sm focus:border-gray-300 ring-1 ring-[#EBEBEB];
    }

    .li-style {
        @apply flex items-center text-[18px] font-[500] h-[52px] p-2 text-txt-lt-clr rounded-lg dark:text-white hover:bg-bgclr dark:hover:bg-gray-700;
    }

    .bankNavbarStyle {
        @apply bg-bgclr text-txt-lt-clr;
    }

    .date-icon {
        @apply flex items-center text-gray-500 absolute ml-[165px]
    }

    .searchBar-style {
        @apply flex justify-center text-[16px] w-[975px] my-6 mr-3 gap-4 text-bgclr;
    }

    .searchBar-input {
        @apply border border-[#EBEBEB] rounded-md w-full pl-6 pr-5 py-3;
    }

    .searchBar-btn {
        @apply bg-bgclr rounded-md px-6 font-[500] text-txtclr;
    }

    .btn-style {
        @apply bg-bgclr rounded-md px-3 py-2 mt-2 font-[500] text-txtclr;
    }

    .btn-style2{
        @apply bg-bgclr rounded-[8px] px-5 py-2 mt-2 text-[16px] font-[500] text-txtclr
    }

    .label-style {
        @apply block text-[14px] font-[500] text-[#666666];
    }

    .required{
        @apply text-red-600 font-bold;
    }

    .error{
        @apply text-red-600 font-semibold;
    }

    .field-split {
        @apply grid md:grid-cols-2 md:gap-5 mt-4
    }  

    .field-split2 {
        @apply grid md:grid-cols-3 md:gap-5
    }

    .error-style {
        @apply text-red-500 font-semibold
    }

    .popup-style1{
        @apply bg-gray-500 fixed inset-0 flex bg-opacity-50 h-screen justify-center items-center overflow-y-scroll;
    }
}

.stdropdown-input {
    display: flex;
    border: 1.5px solid #0B1E59;
    border-radius: 5px;
    padding: 0.3em;
}

.stdropdown-input input {
    border-radius: 5px;
}

.stdropdown-tool {
    /* @media (min-width: 1024px) {
      margin-left: 1.5em;
    }
    @media (min-width: 340px) {
      margin-left: 8.5em;
    }
    padding: 0.5em; */
    display: none;
}

.stdropdown-menu {
    position: relative;

    @media (min-width: 768px) {
        position: absolute;
    }

    max-height: 250px;
    overflow-y: auto;
    width: 240px;
    border: 1px solid #0B1E59;
    padding: 0.5em;
    margin-left: 8px;
    background-color: white;
}

div.popup-overlay {
    position: absolute;
}

#popup-root .popup-content {
    border: none;
    background: none;
}

.swal2-actions .swal2-confirm {
    background-color: #1D4ED8;
}

.tox-statusbar__branding {
    display: none;
}

#swal2-title {
    font-size: 16px;
}

.swal2-error {
    width: 48px;
    height: 48px;
    margin-top: 20px;
}

.swal2-x-mark {
    font-size: 10px;
}

.swal2-warning {
    width: 50px;
    height: 50px;
}

.swal2-warning .swal2-icon-content {
    font-size: 30px;
}

.swal2-popup {
    width: 486px;
    line-height: 5px;
    border-radius: 10px;
}

#swal2-title {
    font-size: 20px;
}

#swal2-html-container {
    font-size: 16px;
    margin-top: 20px;
}

.swal2-actions .swal2-confirm {
    width: 450px;
    background-color: #0B1E59 !important;
    color: white !important;
    font-weight: 500;
    font-size: 16px;
    margin-top: -10px;
    height: 5.5vh;
    border: none !important;
    box-shadow: none !important;
    border-radius: 8px;
    margin-bottom: -5px;
}